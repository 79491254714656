import http from "@/plugins/axios";

export const ApiService = {
  query(resource, params) {
    return http.get(resource, {
      params,
    }).catch(error => {
      // throw error;
    });
  },

  get(resource, slug = "") {
    return http.get(`${resource}/${slug}`).catch(error => {
      // throw error;
    });
  },

  post(resource, params) {
    return http.post(`${resource}`, params).catch(error => {
      throw error;
    });
  },

  update(resource, slug, params) {
    return http.put(`${resource}/${slug}`, params).catch(error => {
      throw error;
    });
  },

  put(resource, params) {
    return http.put(`${resource}`, params).catch(error => {
      throw error;
    });
  },

  delete(resource) {
    return http.delete(resource).catch(error => {
      throw error;
    });
  }
};

export const TagService = {
  query(params) {
    return ApiService.query("tag", params);
  },
  get(slug = "") {
    return ApiService.get("tag", slug);
  }
};

export const PostService = {
  query(params) {
    return ApiService.query("post", params);
  },

  get(slug = "") {
    return ApiService.get("post", slug);
  }
};

export const CategoryService = {
  query(params) {
    return ApiService.query("category", params);
  },

  get(slug = "") {
    return ApiService.get("category", slug);
  }
};


export const AuthorService = {
  query(params) {
    return ApiService.query("author", params);
  },

  get(slug = "") {
    return ApiService.get("author", slug);
  }
};

export const ProductService = {
  query(params) {
    return ApiService.query("product", params);
  },

  get(slug = "") {
    return ApiService.get("product", slug);
  },

  comments(slug = "") {
    return ApiService.get("comment", slug);
  },

  awards(slug = "") {
    return ApiService.get("award", slug);
  },
};

export const BannerService = {
  query(params) {
    return ApiService.query("banner", params);
  },

  get(slug = "") {
    return ApiService.get("banner", slug);
  }
};

export const CourseService = {
  query(params) {
    return ApiService.query("course", params);
  },

  get(slug = "") {
    return ApiService.get("course", slug);
  }
};