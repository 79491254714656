import { createRouter, createWebHistory } from "vue-router"
import store from '@/store'

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import(/* webpackChunkName: "page" */"@/views/index.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "page" */"@/views/about.vue"),
  },
  {
    path: "/cooperation",
    name: "cooperation",
    component: () => import(/* webpackChunkName: "page" */"@/views/cooperation.vue"),
  },
  {
    path: "/event",
    name: "event",
    component: () => import(/* webpackChunkName: "page" */"@/views/event.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import(/* webpackChunkName: "page" */"@/views/privacy.vue"),
  },
  {
    path: "/subscription",
    name: "subscription",
    component: () => import(/* webpackChunkName: "page" */"@/views/subscription.vue"),
  },
  {
    path: "/article",
    name: "article-list",
    component: () => import(/* webpackChunkName: "page" */"@/views/article/list.vue"),
  },
  {
    path: "/article/:no",
    name: "article",
    component: () => import(/* webpackChunkName: "page" */"@/views/article/page.vue"),
    props: true,
  },
  {
    path: "/search",
    name: "search",
    component: () => import(/* webpackChunkName: "page" */"@/views/search.vue"),
    props: route => ({ keyword: route.query.keyword, tag: route.query.tag, type: route.query.type }),
    children: [
      {
        path: 'all',
        component: () => import(/* webpackChunkName: "page" */"@/views/search/all.vue"),
      },
      {
        path: 'wine',
        component: () => import(/* webpackChunkName: "page" */"@/views/search/wine.vue"),
      },
      {
        path: 'article',
        component: () => import(/* webpackChunkName: "page" */"@/views/search/article.vue"),
      },
    ],
  },
  {
    path: "/layout",
    name: "layout",
    component: () => import(/* webpackChunkName: "page" */"@/views/layout.vue"),
  },
  {
    path: "/expert",
    name: "expert",
    component: () => import(/* webpackChunkName: "page" */"@/views/expert.vue"),
  },
  {
    path: "/expert/all",
    name: "expert-all",
    component: () => import(/* webpackChunkName: "page" */"@/views/expert/all.vue"),
  },
  {
    path: "/expert/page",
    name: "expert-page",
    component: () => import(/* webpackChunkName: "page" */"@/views/expert/page.vue"),
  },
  {
    path: "/product",
    name: "product-list",
    component: () => import(/* webpackChunkName: "page" */"@/views/product/list.vue"),
  },
  {
    path: "/product/:no",
    name: "product",
    component: () => import(/* webpackChunkName: "page" */"@/views/product/page.vue"),
    props: true,
  },
  {
    path: '/404.html',
    component: () => import(/* webpackChunkName: "page" */"@/views/notfound.vue"),
  },
  {
    path: '/:pathMatch(.*)',
    beforeEnter (to) {
      window.location = '/404.html'
    },
    component: () => import(/* webpackChunkName: "page" */"@/views/notfound.vue"),
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
    store.commit('clearMenu')
    return { top: 0 };
  },
  routes
})

router.beforeEach((to, from) => {
  store.commit('setLoading', true)
  store.commit('setMeta', {
    title: `《酒訊雜誌 Wine & Spirits Digest》`,
    og: {
      url: window.location.href,
      type: 'website',
      title: `《酒訊雜誌 Wine & Spirits Digest》`,
      description: '《WSD酒訊雜誌》於1990年成立，是台灣酒界唯一專業月刊雜誌，2020年啟動網路轉型計畫。主辦過「艾雷島嘉年華」、「威士忌嘉年華」、「台北葡萄酒生活節」、「日本酒主義」、「TOP 100 WINES百大葡萄酒展」、「西班牙La Mancha產區品酒會」、「葡萄牙品酒會」等百場活動。曾出版《Whisky100》、《40度以上的天堂烈酒購買指南》、《好瓶!時尚酒莊葡萄酒選購指南》、《威語錄》等叢書。',
      image: 'https://drinker.s3.ap-northeast-1.amazonaws.com/logo.jpg',
    },
    meta: [
      {
        property: 'fb:app_id',
        content: 671097574106450,
      },
      {
        name: 'description',
        content: '《WSD酒訊雜誌》於1990年成立，是台灣酒界唯一專業月刊雜誌，2020年啟動網路轉型計畫。主辦過「艾雷島嘉年華」、「威士忌嘉年華」、「台北葡萄酒生活節」、「日本酒主義」、「TOP 100 WINES百大葡萄酒展」、「西班牙La Mancha產區品酒會」、「葡萄牙品酒會」等百場活動。曾出版《Whisky100》、《40度以上的天堂烈酒購買指南》、《好瓶!時尚酒莊葡萄酒選購指南》、《威語錄》等叢書。',
      },
    ],
  })
  return true;
})


export default router